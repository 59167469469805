.range-slider {
  margin: auto;
  text-align: center;
  position: relative;
  --background: #ccc;
  --thumb:  #0062ff;
  --thumb-border: #0062ff;
}
.range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}

.range-slider input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  appearance: textfield;
  -moz-appearance: textfield;
}

.range-slider input[type="number"]::-webkit-outer-spin-button,
.range-slider input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.range-slider input[type="number"]:invalid,
.range-slider input[type="number"]:out-of-range {
  border: 2px solid #ff6347;
}

.range-slider input[type="range"] {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
}

.range-slider input[type="range"]:focus {
  outline: none;
}

.range-slider input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--background);
}

.range-slider input[type="range"]:focus::-ms-fill-lower {
  background: var(--background);
}

.range-slider input[type="range"]:focus::-ms-fill-upper {
  background: var(--background);
}

.range-slider input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: var(--background);
  box-shadow: none;
  border: 0;
  border-radius:14px;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  z-index: 20;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid var(--thumb-border);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--thumb);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
