.rdp {
  --rdp-cell-size: 35px;
  --rdp-accent-color: #0062ff;
  --rdp-background-color: #ddeaff;
  --rdp-accent-color-dark: #0062ff;
  --rdp-background-color-dark: #0062ff;
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  margin: 1em;
}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.rdp-button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  background: none;
  font: inherit;

  -moz-appearance: none;
  -webkit-appearance: none;
}

.rdp-button_reset:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  outline: none;
}

.rdp-button {
  border: 2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 0.25;
  font-weight:500;
}

.rdp-button {
  cursor: pointer;
}
.rdp-button[disabled] {
  cursor: default;
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  margin: 0;
  border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
  border-collapse: collapse;
}

.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
}

.rdp-multiple_months .rdp-caption {
  position: relative;
  display: block;
  text-align: center;
}

.rdp-caption_dropdowns {
  position: relative;
  display: inline-flex;
}

.rdp-caption_label {
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: inherit;
  font-weight: bold;
  flex-grow: 1;
}
.rdp-caption_label:after {
  content: "";
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-left: 5px;
}
.rdp-nav {
  white-space: nowrap;
  color: #aaa;
  display: flex;
  gap: 0.5rem;
}

.hide-nav .rdp-nav {
  display: none;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  padding: 0.25em;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  border: 1px solid #ccc;
}
.rdp-nav_button:hover {
  color: #333;
  border-color: #888;
}
.rdp-nav_button:first-child {
  transform: rotate(90deg);
}
.rdp-nav_button:last-child {
  transform: rotate(90deg);
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown {
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
  font-weight: 600;
  color: #bbb;
}

.rdp-head_row,
.rdp-row {
  height: 100%;
  width: 30px;
  /* hover: */
}

/* Makes the row colored on hover */
/*
.rdp-row:hover > .rdp-cell:not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
} 
.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}
*/


.single-calendar .rdp-cell{
  justify-content:center;
  text-align:center;
}
.single-calendar .rdp-cell> button{
  width:35px;
  height:35px;
}
.single-calendar .rdp-cell> button:not([disabled],.rdp-day_selected):hover{
  background-color:var(--rdp-accent-color);
  opacity:0.5;
  color: white;
  border-radius:50%;
}
.single-calendar .rdp-day_selected,
.single-calendar .rdp-day_selected:focus-visible,
.single-calendar .rdp-day_selected:hover {
  color: white;
  opacity: 1;
  background-color: var(--rdp-accent-color);
  border-radius:50%;
}

/* Customizations end */

.rdp-head_cell {
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 0.75em;
  text-align: center;
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
}

.rdp-tbody {
  border: 0;
}
.rdp-tfoot {
  margin: 0.5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
}
.range-calendar .rdp-cell:first-child > * {
  border-radius: 5px 0 0 5px;
}
.range-calendar .rdp-cell:last-child > * {
  border-radius: 0 5px 5px 0;
}
.rdp-weeknumber {
  font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-weight: bold;
  width: calc(var(--rdp-cell-size) * 1.5);
  /* max-width: var(--rdp-cell-size); */
  height: var(--rdp-cell-size);
  margin: 0;
  border: 2px solid transparent;
}

.rdp-day_today:not(.rdp-day_outside):not(.rdp-day_selected) {
  font-weight: bold;
  color: var(--rdp-accent-color);
  opacity: 0.8 !important;
}

.rdp-day_outside {
  opacity: 0.6;
}

.rdp-day_selected:focus-visible {
  /* Since the background is the same use again the outline */
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}
